<template>
    <div class="container1150">
        <header><strong>防洪排涝管理平台 </strong></header>
        <main>
            <div class="img1"></div>
        </main>
    </div>
</template>
<script>
export default {
    data() {
        return {};
    },
};
</script>
<style lang="scss" scoped>
.container1150 {
    width: 1150px;
    header {
        height: 70px;
        line-height: 70px;
        text-align: center;
        font-size: 26px;
    }
    main {
        p {
            // height: 20px;
            font-size: 16px;
            line-height: 30px;
        }
        h2 {
            height: 60px;
            line-height: 60px;
        }
        .text-img {
            display: flex;
            justify-content: space-around;
            p {
                width: 40%;
                padding: 50px 0 0 20px;
                box-sizing: border-box;
            }
            .img1 {
                // width: 100%;
                width: 500px;
                height: 300px;
                margin: -60px auto 0;
                background-image: url("/img/product/6-3.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }

        .img-list {
            display: flex;
            width: 100%;
            .img1 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/7-1.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
            .img2 {
                // width: 100%;
                width: 550px;
                height: 450px;
                margin: 10px auto;
                background-image: url("/img/product/7-2.jpg");
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }
        }
        .img1 {
            // width: 100%;
            width: 1100px;
            height: 450px;
            margin: 10px auto;
            background-image: url("/img/product/18-1.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img2 {
            width: 1100px;
            height: 500px;
            margin: 10px auto;
            background-image: url("/img/product/17-2.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img3 {
            width: 1100px;
            height: 500px;
            margin: 10px auto;
            background-image: url("/img/product/17-3.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img4 {
            width: 900px;
            height: 1100px;
            margin: 10px auto;
            background-image: url("/img/product/17-4.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
        .img5 {
            width: 900px;
            height: 1100px;
            margin: 10px auto;
            background-image: url("/img/product/7-7.jpg");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }
}
</style>